@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

*::webkit::-webkit-scrollbar {
  display: none;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: 0.4s all;
  font-family: "Nunito", sans-serif;
  scroll-behavior: smooth;
}
.swiper-button-next,
.swiper-button-prev {
  color: #cc8664 !important;
}
.slider {
  opacity: 0;
  transform: scale(85%);
  transition: 2s;
}
.slider_zoom {
  opacity: 1;
  transform: scale(100%);
  transition: 2s;
}
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  border: none;
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0 1000px #f2f5fa inset;
  transition: background-color 5000s ease-in-out 0s;
}

.dash_webkit::-webkit-scrollbar {
  display: none;
}

.tradingview-widget-copyright {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  td {
    font-size: 12px;
  }
  th {
    font-size: 12px;
  }
}
